<template>
  <q-layout view="hHh lpr fff">
    <q-header bordered>
      <q-toolbar class="bg-secondary text-weight-bolder">
        <q-toolbar-title>
          <Logo />
        </q-toolbar-title>
        <q-btn v-if="false"></q-btn>
      </q-toolbar>
    </q-header>

    <q-page-container>
      <router-view />
    </q-page-container>
    <q-footer
      class=" text-grey-9"
      style="-webkit-backdrop-filter: blur(7px) !important; backdrop-filter: blur(7px) !important; background-color: #fafafa1a !important;"
    >
      <q-toolbar>
        <q-toolbar-title>
          <div class="text-body2 text-center">Power by <b>Abraão Pinto</b></div>
        </q-toolbar-title>
        <div class="text-body2">©2022 v{{ appVersion }}</div>
      </q-toolbar>
    </q-footer>
  </q-layout>
</template>
<script>
import { defineAsyncComponent, defineComponent } from 'vue'

export default defineComponent({
  name: 'CleanLayout',
  components: {
    Logo: defineAsyncComponent(() => import('@/components/Logo'))
  },
  computed: {
    appVersion () {
      return process.env.VUE_APP_VERSION
    }
  },
  setup () {

  }
})
</script>
<style scoped>
/* Centralizar logo no mobile */
body.mobile .q-toolbar__title {
  text-align: center;
}
</style>
